////////////////////////////// Auth //////////////////////////////
export const CHECK_USER_AUTHENTICATED = 'check_user_authenticated';
export const USER_AUTHENTICATED_SUCCESS = 'user_authenticated_success';
export const USER_AUTHENTICATED_FAILURE = 'user_authenticated_failure';
export const LOGIN_USER_EMAIL_PASSWORD = 'login_user_email_password';
export const LOGIN_USER_FAILURE = 'login_user_failure';
export const LOGOUT_USER = 'logout_user';
export const LOGOUT_USER_SUCCESS = 'logout_user_success';
export const CLEAR_AUTH_ERROR = 'clear_auth_error';
export const RESET_USER_PASSWORD = 'reset_user_password';
export const NON_AUTH_RESET_USER_PASSWORD = 'non_auth_reset_user_password';
export const NON_AUTH_RESET_USER_PASSWORD_SUCCESS =
    'non_auth_reset_user_password_success';
export const NON_AUTH_RESET_USER_PASSWORD_FAILURE =
    'non_auth_reset_user_password_failure';
export const RESET_PASSWORD_REQUEST_STATE = 'reset_password_request_state';
export const EXECUTE_RESET_PASSWORD_FLIGHT = 'execute_reset_password_flight';
export const VERIFY_ACTION_CODE = 'verify_action_code';
export const ACTION_CODE_VALID = 'action_code_valid';
export const ACTION_CODE_INVALID = 'action_code_invalid';
export const RESET_PASSWORD_SUCCESS = 'reset_password_success';
export const RESET_PASSWORD_FAILURE = 'reset_password_failure';

export const UPDATE_AUTH_USER_PASSWORD = 'update_auth_user_password';
export const UPDATE_AUTH_USER_PASSWORD_SUCCESS = 'update_auth_user_password_success';
export const UPDATE_AUTH_USER_PASSWORD_FAILURE = 'update_auth_user_password_failure';
export const UPDATE_AUTH_USER_PASSWORD_RESET = 'update_auth_user_password_reset';

export const UPDATE_AUTH_USER_EMAIL = 'update_auth_user_email';
export const UPDATE_AUTH_USER_EMAIL_SUCCESS = 'update_auth_user_email_success';
export const UPDATE_AUTH_USER_EMAIL_FAILURE = 'update_auth_user_email_failure';
export const UPDATE_AUTH_USER_EMAIL_RESET = 'update_auth_user_email_reset';

////////////////////////////// Calls ///////////////////////////////
export const GET_CALLS_COLLECTION = 'get_calls_collection';
export const STORE_CALLS_COLLECTION = 'store_calls_collection';
export const FIELD_PANEL_CALL = 'field_panel_call';
export const END_PANEL_CALL = 'end_panel_call';
export const FIELD_PANEL_CALL_SUCCESS = 'field_panel_call_success';
export const END_PANEL_CALL_SUCCESS = 'end_panel_call_success';
export const FIELD_PANEL_CALL_FAILURE = 'field_panel_call_failure';
export const END_PANEL_CALL_FAILURE = 'end_panel_call_failure';
export const ALLOW_GATE_ACCESS = 'allow_gate_access';
export const ALLOW_GATE_ACCESS_SUCCESS = 'allow_gate_access_success';
export const ALLOW_GATE_ACCESS_FAILURE = 'allow_gate_access_failure';

////////////////////////////// Members /////////////////////////////
export const ADD_NEW_MEMBER = 'add_new_member';
export const ADD_NEW_MEMBER_SUCCESS = 'add_new_member_success';
export const ADD_NEW_MEMBER_FAILURE = 'add_new_member_failure';
export const RESET_ADD_NEW_MEMBER = 'reset_add_new_member';
export const GET_EXISTING_MEMBER = 'get_existing_member';
export const SET_EXISTING_MEMBER = 'set_existing_member';
export const REMOVE_MEMBERS = 'remove_members';
export const UPDATE_MEMBER = 'update_member';
export const REMOVE_REGISTERS = 'remove_registers';
export const UPDATE_REGISTER = 'update_register';
export const SEND_INVITES = 'send_invites';
export const REMOVE_PENDING = 'remove_pending';

////////////////////////////// Panels //////////////////////////////
export const GET_PANEL_DATA = 'get_panel_data';
export const SET_PANEL_DATA = 'set_panel_data';

export const GET_DEVICE_TYPES = 'get_device_types';
export const GET_DEVICE_TYPES_SUCCESS = 'get_device_types_success';
export const GET_DEVICE_TYPES_FAILURE = 'get_device_types_failure';

export const WATCH_ORG_DEVICES_RTDB = 'watch_org_devices_rtdb';
export const WATCH_ORG_DEVICES_RTDB_SUCCESS = 'watch_org_devices_rtdb_success';
export const WATCH_ORG_DEVICES_RTDB_FAILURE = 'watch_org_devices_rtdb_failure';

export const UPDATE_ORG_DEVICE_DETAILS = 'update_org_device_details';
export const UPDATE_ORG_DEVICE_DETAILS_SUCCESS = 'update_org_device_details_success';
export const UPDATE_ORG_DEVICE_DETAILS_FAILURE = 'update_org_device_details_failure';
export const UPDATE_ORG_DEVICE_DETAILS_RESET = 'update_org_device_details_reset';

////////////////////////////// Org //////////////////////////////
export const GET_ORGS_COLLECTION = 'get_orgs_collection';
export const GET_ORGS_COLLECTION_SUCCESS = 'get_orgs_collection_success';
export const GET_ORGS_COLLECTION_FAILURE = 'get_orgs_collection_failure';
export const GET_ACTIVE_ORG_COLLECTION = 'get_active_org_collection';
export const GET_ACTIVE_ORG_COLLECTION_SUCCESS = 'get_active_org_collection_success';
export const GET_ACTIVE_ORG_COLLECTION_FAILURE = 'get_active_org_collection_failure';

export const GET_USER_ORG = 'get_user_org';
export const SET_USER_ORG = 'set_user_org';
export const SET_PRIMARY_ORG = 'set_primary_org';

export const CREATE_ORG = 'create_org';
export const CREATE_ORG_SUCCESS = 'create_org_success';
export const CREATE_ORG_FAILURE = 'create_org_failure';
export const RESET_ORG_CREATION_STATE = 'reset_org_creation_state';

export const ADD_INVITE_TO_ORG = 'add_invite_to_org';
export const ADD_INVITE_TO_ORG_SUCCESS = 'add_invite_to_org_success';
export const ADD_INVITE_TO_ORG_FAILURE = 'add_invite_to_org_failure';
export const RESET_INVITE_ADDING_STATE = 'reset_invite_adding_state';

////////////////////////////// Otc //////////////////////////////
export const GET_ORG_OTC_CODES = 'get_org_otc';
export const GET_ORG_OTC_SUCCESS = 'get_org_otc_success';
export const ADD_OTC = 'add_otc';
export const ADD_OTC_SUCCESS = 'add_otc_success';
export const ADD_OTC_FAILURE = 'add_otc_failure';
export const REVOKE_OTC = 'revoke_otc';
export const REVOKE_OTC_SUCCESS = 'revoke_otc_success';
export const REVOKE_OTC_FAILURE = 'revoke_otc_failure';
export const RESET_OTC_STATE = 'reset_otc_state';

////////////////////////////// User collection //////////////////////////////
export const GET_USER_COLLECTION = 'get_user_collection';
export const SET_USER_COLLECTION = 'set_user_collection';
export const SET_ACTIVE_USER_ORG = 'set_active_user_org';
export const SET_ACTIVE_USER_ORG_SUCCESS = 'set_active_user_org_success';
export const SET_ACTIVE_USER_ORG_FAILURE = 'set_active_user_org_failure';

////////////////////////////// Registration /////////////////////////////////
export const ADD_NEW_REGISTRATION = 'add_new_registration';
export const ADD_NEW_REGISTRATION_SUCCESS = 'add_new_registration_success';
export const ADD_NEW_REGISTRATION_FAILURE = 'add_new_registration_failure';
export const RESET_ADD_NEW_REGISTRATION = 'reset_add_new_registration';

////////////////////////////// Reservations /////////////////////////////////

export const ADD_RESERVATION = 'add_reservation';
export const ADD_RESERVATION_SUCCESS = 'add_reservation_success';
export const ADD_RESERVATION_FAILURE = 'add_reservation_failure';
export const RESET_ADD_RESERVATION_STATE = 'reset_add_reservation_state';

export const GET_RESERVATIONS = 'get_reservations';
export const GET_RESERVATIONS_SUCCESS = 'get_reservations_success';
export const GET_RESERVATIONS_FAILURE = 'get_reservations_failure';

export const WATCH_RESERVATIONS = 'watch_reservations';

export const CREATE_TENANT_ACCESS = 'create_tenant_access';
export const CREATE_TENANT_ACCESS_SUCCESS = 'create_tenant_access_success';
export const CREATE_TENANT_ACCESS_FAILURE = 'create_tenant_access_failure';

export const VALIDATE_RESERVATION = 'validate_reservation';
export const VALIDATE_RESERVATION_SUCCESS = 'validate_reservation_success';
export const VALIDATE_RESERVATION_FAILURE = 'validate_reservation_failure';

export const CANCEL_RESERVATION = 'cancel_reservation';
export const CANCEL_RESERVATION_SUCCESS = 'cancel_reservation_success';
export const CANCEL_RESERVATION_FAILURE = 'cancel_reservation_failure';

export const REINSTATE_RESERVATION = 'reinstate_reservation';
export const REINSTATE_RESERVATION_SUCCESS = 'reinstate_reservation_success';
export const REINSTATE_RESERVATION_FAILURE = 'reinstate_reservation_failure';

export const UPDATE_RESERVATION = 'update_reservation';
export const UPDATE_RESERVATION_SUCCESS = 'update_reservation_success';
export const UPDATE_RESERVATION_FAILURE = 'update_reservation_failure';

export const IMPORT_RESERVATIONS_CSV = 'import_reservations_csv';
export const IMPORT_RESERVATIONS_CSV_FAILURE = 'import_reservations_csv_failure';
export const IMPORT_RESERVATIONS_CSV_SUCCESS = 'import_reservations_csv_success';
export const RESET_IMPORT_RESERVATIONS_STATE = 'reset_import_reservations_state';

export const RESET_RESERVATION_ACTIONS_STATE = 'reset_reservation_actions_state';
export const STORE_GUARD_RESERVATION_ID = 'store_guard_reservation_id';

/////////////// Member (Manager, Resident, Vendor) //////////

export const ADD_MEMBER = 'add_member';
export const ADD_MEMBER_SUCCESS = 'add_member_success';
export const ADD_MEMBER_FAILURE = 'add_member_failure';
export const RESET_MEMBER_CREATION_STATE = 'reset_member_creation_state';

export const IMPORT_MEMBERS = 'import_members';
export const IMPORT_MEMBERS_SUCCESS = 'import_members_success';
export const IMPORT_MEMBERS_FAILURE = 'import_members_failure';
export const RESET_MEMBER_IMPORT_STATE = 'reset_member_import_state';

export const GET_IMPORTED_MEMBERS_FOR_ORG = 'get_imported_members';
export const GET_IMPORTED_MEMBERS_FOR_ORG_SUCCESS = 'get_imported_members_success';
export const GET_IMPORTED_MEMBERS_FOR_ORG_FAILURE = 'get_imported_members_failure';

export const SET_INVITE_PENDING_MEMBERS = 'set_invite_pending_members';
export const RESET_INVITE_PENDING_MEMBERS = 'reset_invite_pending_members';

export const ADD_INVITE_PENDING_MEMBERS = 'add_invite_pending_members';
export const ADD_INVITE_PENDING_MEMBERS_SUCCESS = 'add_invite_pending_members_success';
export const ADD_INVITE_PENDING_MEMBERS_FAILURE = 'add_invite_pending_members_failure';

export const GET_INVITED_MEMBERS_FOR_ORG = 'get_invited_members';
export const GET_INVITED_MEMBERS_FOR_ORG_SUCCESS = 'get_invited_members_success';
export const GET_INVITED_MEMBERS_FOR_ORG_FAILURE = 'get_invited_members_failure';

export const GET_COMMUNITY_MEMBERS_FOR_ORG = 'get_community_members';
export const GET_COMMUNITY_MEMBERS_FOR_ORG_SUCCESS = 'get_community_members_success';
export const GET_COMMUNITY_MEMBERS_FOR_ORG_FAILURE = 'get_community_members_failure';

export const GET_ACCESS_KEYS_DOCUMENT = 'get_access_keys_document';
export const GET_ACCESS_KEYS_DOCUMENT_SUCCESS = 'get_access_keys_document_success';
export const GET_ACCESS_KEYS_DOCUMENT_FAILURE = 'get_access_keys_document_failure';

export const ADD_ACCESS_KEYS_DOCUMENT = 'add_access_keys_document';
export const ADD_ACCESS_KEYS_DOCUMENT_SUCCESS = 'add_access_keys_document_success';
export const ADD_ACCESS_KEYS_DOCUMENT_FAILURE = 'add_access_keys_document_failure';

export const GET_POSTS = 'get_posts';
export const GET_POSTS_SUCCESS = 'get_posts_success';
export const GET_POSTS_FAILURE = 'get_posts_failure';

export const ADD_POST = 'add_post';
export const ADD_POST_SUCCESS = 'add_post_success';
export const ADD_POST_FAILURE = 'add_post_failure';

export const DELETE_POST = 'delete_post';
export const DELETE_POST_SUCCESS = 'delete_post_success';
export const DELETE_POST_FAILURE = 'delete_post_failure';

export const UPDATE_ACCESS_LOG = 'update_access_log';

export const VALIDATE_INVITE = 'validate_invite';
export const VALIDATE_INVITE_SUCCESS = 'validate_invite_success';
export const VALIDATE_INVITE_FAILURE = 'validate_invite_failure';
export const SET_INVITE_PROCESSING_STATUS = 'set_invite_processing_status';
export const DECLINE_INVITE = 'decline_invite';
export const DECLINE_INVITE_SUCCESS = 'decline_invite_success';
export const DECLINE_INVITE_FAILURE = 'decline_invite_failure';
export const ACCEPT_INVITE = 'accept_invite';
export const ACCEPT_INVITE_SUCCESS = 'accept_invite_success';
export const ACCEPT_INVITE_FAILURE = 'accept_invite_failure';
export const ADD_MEMBERS_FROM_LIST = 'add_members_from_list';

//////// confirm reservation process

export const CREATE_ACCESS_API_CALL = 'create_access_api_call';
export const CREATE_ACCESS_API_SUCCESS = 'create_access_api_success';
export const CREATE_ACCESS_API_MATCHED_ACCOUNT = 'create_access_api_matched_account';
export const CREATE_ACCESS_API_VERIFY_NUMBER = 'create_access_api_verify_number';
export const CREATE_ACCESS_API_NEW_VERIFICATION = 'create_access_api_new_verification';
export const CREATE_ACCESS_API_NEW_VERIFICATION_ADDED =
    'create_access_api_new_verification_added';
export const CREATE_ACCESS_API_VERIFICATION_SUCCESS =
    'create_access_api_verification_success';
export const CREATE_ACCESS_API_ERROR_MESSAGE = 'create_access_api_error_message';
export const CREATE_ACCESS_API_START_OVER = 'create_access_api_start_over';

/////// user account

export const UPDATE_USER_AVATAR = 'update_user_avatar';
export const UPDATE_USER_AVATAR_SUCCESS = 'update_user_avatar_success';
export const UPDATE_USER_AVATAR_FAILURE = 'update_user_avatar_failure';

export const UPDATE_USER_PROFILE_INFO = 'update_user_profile_info';
export const UPDATE_USER_PROFILE_INFO_SUCCESS = 'update_user_profile_info_success';
export const UPDATE_USER_PROFILE_INFO_FAILURE = 'update_user_profile_info_failure';
export const UPDATE_USER_PROFILE_INFO_RESET = 'update_user_profile_info_reset';

////////////////////////////// Resi Guests /////////////////////////////////

export const ADD_NEW_GUEST = 'add_new_guest';
export const GET_GUESTS = 'get_guests';
export const SET_GUEST_INVITES = 'set_guest_invites';
export const SET_GUEST_ACCESS_KEYS = 'set_guest_access_keys';
export const SET_ONE_TIME_ACCESS_CODES = 'set_one_time_access_codes';
export const ADD_NEW_GUEST_SUCCESS = 'add_new_guest_success';
export const ADD_NEW_GUEST_FAILURE = 'add_new_guest_failure';
export const RESET_ADD_NEW_GUEST = 'reset_add_new_guest';
export const REMOVE_GUEST = 'remove_guest';
export const REMOVE_GUEST_SUCCESS = 'remove_guest_sucess';
export const REMOVE_GUEST_FAILURE = 'remove_guest_failure';
export const RESET_REMOVE_GUEST = 'reset_remove_guest';
export const ADD_ONE_TIME_ACCESS_CODE = 'add_one_time_access_code';
export const ADD_ONE_TIME_ACCESS_CODE_SUCCESS = 'add_one_time_access_code_success';
export const ADD_ONE_TIME_ACCESS_CODE_FAILURE = 'add_one_time_access_code_failure';
export const RESET_ADD_ONE_TIME_ACCESS_CODE = 'reset_add_one_time_access_code';
export const REVOKE_ONE_TIME_ACCESS_CODE = 'revoke_one_time_access_code';
export const REVOKE_ONE_TIME_ACCESS_CODE_SUCCESS = 'revoke_one_time_access_code_success';
export const REVOKE_ONE_TIME_ACCESS_CODE_FAILURE = 'revoke_one_time_access_code_failure';
export const RESET_REVOKE_ONE_TIME_ACCESS_CODE = 'reset_revoke_one_time_access_code';

///////////////////////// Super Admin ///////////////////////////

export const ADD_GUEST_INVITES = 'add_guest_invites';
export const ADD_GUEST_INVITES_SUCCESS = 'add_guest_invites_success';

///////////////////////// Request Removal ///////////////////////////

export const REQUEST_ACCOUNT_REMOVAL = 'request_account_removal';
export const REQUEST_ACCOUNT_REMOVAL_SUCCESS = 'request_account_removal_success';
export const REQUEST_ACCOUNT_REMOVAL_FAILURE = 'request_account_removal_failure';
export const RESET_REQUEST_ACCOUNT_REMOVAL = 'reset_request_account_removal';

///////////////////////// Maintenance Request ///////////////////////////
export const ADD_MAINTENANCE_REQUEST = 'add_maintenance_request';
export const ADD_MAINTENANCE_REQUEST_SUCCESS = 'add_maintenance_request_success';
export const ADD_MAINTENANCE_REQUEST_FAILURE = 'add_maintenance_request_failure';
