// React
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';

// Assets

// Components
import { Loader } from '../components';

// Pages
import { Dashboard, RequestDetails } from '../pages';

// Packages
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Redux - Actions, Reducers, Sagas

//Router
import * as routes from './config/routes';

const AuthenticatedRouter = ({ authUser, userData, match, history }) => {
    const [mount, setMount] = useState(false);
    const [redirect, setRedirect] = useState(null);

    if (!redirect && !mount) return <Loader />;

    return (
        <div style={{ height: '100vh' }}>
            <div
                className="d-flex flex-column w-100 h-100 flex-auto"
                style={{ minWidth: '0' }}
            >
                <div className="d-flex justify-content-start h-100">
                    <div className={`main w-100`}>
                        <Switch>
                            <Redirect exact from={`${match.url}`} to={redirect} />
                            <Route
                                authUser={authUser}
                                path={`${match?.url}${routes.DASHBOARD}`}
                                render={props => (
                                    <Dashboard {...props} userData={userData} />
                                )}
                            />
                            <Route
                                authUser={authUser}
                                path={`${match?.url}${routes.REQUEST_DETAILS}`}
                                render={props => (
                                    <RequestDetails {...props} userData={userData} />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthenticatedRouter;
