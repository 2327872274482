import {
    ADD_MAINTENANCE_REQUEST,
    ADD_MAINTENANCE_REQUEST_SUCCESS,
    ADD_MAINTENANCE_REQUEST_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    loading: null,
    error: null
};

const maintenanceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_MAINTENANCE_REQUEST:
            return {
                ...state,
                loading: true,
                error: INITIAL_STATE.error
            };
        case ADD_MAINTENANCE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false
            };
        case ADD_MAINTENANCE_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default maintenanceReducer;
