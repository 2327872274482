import React from 'react';
import MaintenanceBanner from '../assets/maintenance-banner.jpeg';
import { Container, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import { errorMessage, nameRegExp, streetAddressRegExp } from '../utils/Constants';
import LabeledFormField from '../components/common/LabeledFormField';
import ConfirmFooter from '../components/common/ConfirmFooter';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../components/common/Loader';
import ContryPhoneCodeSelect from '../components/common/CountryPhoneCodeSelect';
import { addMaintenanceRequest } from '../store/actions/Maintenance';
// import AddMemberSuccess from './AddMemberSuccess';
import { Button } from 'reactstrap';
// import {
//     checkingExistingMember,
//     addingNewMember,
//     resettingNewMember
// } from '../../store/actions/Members';

import * as routes from '../router/config/routes';

const returnError = (errors, field) => errors[field];

const returnTouched = (touched, field) => touched[field];

const MaintenanceRequest = () => {
    const history = useHistory();
    const loading = false;
    const success = false;
    const submit = values => {
        console.log(values);
        // const phone = values.phone
        //     ? {
        //           number: Number.parseInt(
        //               values.phone.replace(/[^0-9]/g, '').length > 10
        //                   ? values.phone
        //                         .replace(/[^0-9]/g, '')
        //                         .substring(
        //                             values.phone.replace(/[^0-9]/g, '').length - 10
        //                         )
        //                   : values.phone.replace(/[^0-9]/g, '')
        //           ),
        //           code: Number.parseInt(values.code),
        //           country: values.country
        //       }
        //     : null;
        // const phone_number = values.phone
        //     ? values.phone.replace(/[^0-9]/g, '').length > 10
        //         ? `${values.code}${values.phone
        //               .replace(/[^0-9]/g, '')
        //               .substring(values.phone.replace(/[^0-9]/g, '').length - 10)}`
        //         : `${values.code}${values.phone.replace(/[^0-9]/g, '')}`
        //     : null;
        // const address =
        //     type === 'resi'
        //         ? `${
        //               values.address_2.trim() !== ''
        //                   ? `${values.address_1.trim()}, ${values.address_2.trim()}`
        //                   : values.address_1.trim()
        //           }`
        //         : null;
        // const email =
        //     values.email.trim() === '' ? null : values.email.toLowerCase().trim();

        // checkingExistingMember({
        //     member: {
        //         ...values,
        //         first_name: values.first_name.toLowerCase().trim(),
        //         last_name: values.last_name.toLowerCase().trim(),
        //         address_1: values.address_1.trim(),
        //         address_2: values?.address_2 ? values.address_2.trim() : '',
        //         address,
        //         email,
        //         phone,
        //         phone_number,
        //         type
        //     }
        // });
    };
    // title, description, adress, unit. Number, availability for scheduling and can we enter if they aren’t home

    const initialValues = {
        first_name: '',
        last_name: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        zip: '',
        email: '',
        phone: '',
        code: '1',
        country: 'US',
        issue_type: '',
        availability: '',
        description: ''
    };

    const validationSchema = Yup.object().shape(
        {
            first_name: Yup.string()
                .trim()
                .matches(nameRegExp.format, errorMessage.firstName.valid)
                .required(errorMessage.firstName.required),
            last_name: Yup.string()
                .trim()
                .matches(nameRegExp.format, errorMessage.lastName.valid)
                .required(errorMessage.lastName.required),
            email: Yup.string()
                .trim()
                .lowercase()
                .email(errorMessage.email.valid)
                .when('phone', {
                    is: phone => !phone || phone.length === 0,
                    then: Yup.string().required(errorMessage.emailOrPhone.required),
                    otherwise: Yup.string()
                }),
            address_1: Yup.string()
                .trim()
                .matches(streetAddressRegExp, errorMessage.address.valid)
                .required(errorMessage.streetAddress.required),
            address_2: Yup.string().trim(),
            city: Yup.string()
                .trim()
                .matches(nameRegExp.format, errorMessage.city.valid)
                .required(errorMessage.city.required),
            state: Yup.string().trim().required(errorMessage.state.required),
            zip: Yup.string().trim().required(errorMessage.zip.required),
            phone: Yup.string()
                .trim()
                .when('email', {
                    is: email => !email || email.length === 0,
                    then: Yup.string().required(errorMessage.phoneOrEmail.required),
                    otherwise: Yup.string().trim()
                }),
            country: Yup.string().trim(),
            code: Yup.string().trim(),
            issue_type: Yup.string().required('Type of Issue is required'),
            description: Yup.string().required('Dexcription of issue is required'),
            availability: Yup.string().required('Describe your availability')
        },
        [
            ['phone', 'email'],
            ['email', 'phone']
        ]
    );

    const validatePhone = ({ phone: { code, number, country } }) => {
        const phoneSchema = Yup.string().phone(country);
        const isValid = number ? phoneSchema.isValidSync(code + number) : true;
        return isValid ? undefined : { phone: { number: 'invalid number' } };
    };

    // if (success) {
    //     return (
    //         <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
    //             <AddMemberSuccess type={type} found={found} />
    //         </div>
    //     );
    // }

    return (
        <div className="minus-header bg-light" style={{ overflow: 'hidden' }}>
            <div className="center">
                <div style={{ zIndex: 0 }}>
                    <img
                        src={MaintenanceBanner}
                        alt="banner"
                        style={{
                            width: '100vw',
                            // height: '40vh',
                            objectFit: 'scale-down'
                        }}
                    />
                </div>

                {loading && (
                    <div style={{ position: 'absolute', top: '63vh', left: '59vw' }}>
                        <Loader />
                    </div>
                )}

                <div
                    style={{
                        width: '80vw',
                        zIndex: 100,
                        position: 'absolute',
                        right: 0,
                        top: '120px'
                    }}
                >
                    <Container className="space-1">
                        <Col>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={submit}
                                validate={validatePhone}
                                validationSchema={validationSchema}
                                validateOnChange
                                enableReinitialize
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    touched,
                                    values
                                }) => (
                                    <>
                                        <Form className="w-md-70 mx-md-auto">
                                            {!success ? (
                                                <>
                                                    <div className="mb-4">
                                                        <h2 className="h5 font-weight-normal mb-0">
                                                            <span className="d-flex justify-content-start h3 font-weight-semi-bold">
                                                                Maintenance Request
                                                            </span>
                                                        </h2>
                                                    </div>
                                                    <div className="js-form-message form-group">
                                                        <div className="d-flex flex-row justify-content-start">
                                                            <div
                                                                style={{ flex: 1 }}
                                                                className="js-form-message col-6 d-flex form-group"
                                                            >
                                                                <LabeledFormField
                                                                    fieldTitle="first name"
                                                                    required
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="first_name"
                                                                    id="first_name"
                                                                    aria-label="first_name"
                                                                    error={returnError(
                                                                        errors,
                                                                        'first_name'
                                                                    )}
                                                                    value={
                                                                        values.first_name
                                                                    }
                                                                    onBlur={handleBlur(
                                                                        'first_name'
                                                                    )}
                                                                    onChange={handleChange(
                                                                        'first_name'
                                                                    )}
                                                                    invalid={
                                                                        returnTouched(
                                                                            touched,
                                                                            'first_name'
                                                                        ) &&
                                                                        !!returnError(
                                                                            errors,
                                                                            'first_name'
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                style={{ flex: 1 }}
                                                                className="js-form-message col-6 d-flex form-group"
                                                            >
                                                                <LabeledFormField
                                                                    fieldTitle="last name"
                                                                    required
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="last_name"
                                                                    id="last_name"
                                                                    aria-label="last_name"
                                                                    error={returnError(
                                                                        errors,
                                                                        'last_name'
                                                                    )}
                                                                    value={
                                                                        values.last_name
                                                                    }
                                                                    onBlur={handleBlur(
                                                                        'last_name'
                                                                    )}
                                                                    onChange={handleChange(
                                                                        'last_name'
                                                                    )}
                                                                    invalid={
                                                                        returnTouched(
                                                                            touched,
                                                                            'last_name'
                                                                        ) &&
                                                                        !!returnError(
                                                                            errors,
                                                                            'last_name'
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="js-form-message form-group">
                                                        <div className="d-flex flex-row justify-content-start">
                                                            <div
                                                                style={{ flex: 3 }}
                                                                className="js-form-message col-8 d-flex form-group"
                                                            >
                                                                <LabeledFormField
                                                                    fieldTitle="Street address"
                                                                    required
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="address_1"
                                                                    id="address_1"
                                                                    // placeholder="Street address"
                                                                    aria-label="address_1"
                                                                    error={returnError(
                                                                        errors,
                                                                        'address_1'
                                                                    )}
                                                                    value={
                                                                        values.address_1
                                                                    }
                                                                    onBlur={handleBlur(
                                                                        'address_1'
                                                                    )}
                                                                    onChange={handleChange(
                                                                        'address_1'
                                                                    )}
                                                                    invalid={
                                                                        returnTouched(
                                                                            touched,
                                                                            'address_1'
                                                                        ) &&
                                                                        !!returnError(
                                                                            errors,
                                                                            'address_1'
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                style={{ flex: 1 }}
                                                                className="js-form-message col-4 d-flex form-group"
                                                            >
                                                                <LabeledFormField
                                                                    fieldTitle="Unit"
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="address_2"
                                                                    id="address_2"
                                                                    // placeholder="Unit"
                                                                    aria-label="address_2"
                                                                    error={returnError(
                                                                        errors,
                                                                        'address_2'
                                                                    )}
                                                                    value={
                                                                        values.address_2
                                                                    }
                                                                    onBlur={handleBlur(
                                                                        'address_2'
                                                                    )}
                                                                    onChange={handleChange(
                                                                        'address_2'
                                                                    )}
                                                                    invalid={
                                                                        returnTouched(
                                                                            touched,
                                                                            'address_2'
                                                                        ) &&
                                                                        !!returnError(
                                                                            errors,
                                                                            'address_2'
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="js-form-message form-group">
                                                        <div className="d-flex flex-row justify-content-start">
                                                            <div
                                                                style={{ flex: 1 }}
                                                                className="js-form-message col-4 d-flex form-group"
                                                            >
                                                                <LabeledFormField
                                                                    fieldTitle="City"
                                                                    required
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="city"
                                                                    id="city"
                                                                    // placeholder="Unit"
                                                                    aria-label="city"
                                                                    error={returnError(
                                                                        errors,
                                                                        'city'
                                                                    )}
                                                                    value={values.city}
                                                                    onBlur={handleBlur(
                                                                        'city'
                                                                    )}
                                                                    onChange={handleChange(
                                                                        'city'
                                                                    )}
                                                                    invalid={
                                                                        returnTouched(
                                                                            touched,
                                                                            'city'
                                                                        ) &&
                                                                        !!returnError(
                                                                            errors,
                                                                            'city'
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                style={{ flex: 1 }}
                                                                className="js-form-message col-4 d-flex form-group"
                                                            >
                                                                <LabeledFormField
                                                                    fieldTitle="State"
                                                                    required
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="state"
                                                                    id="state"
                                                                    // placeholder="Unit"
                                                                    aria-label="state"
                                                                    error={returnError(
                                                                        errors,
                                                                        'state'
                                                                    )}
                                                                    value={values.state}
                                                                    onBlur={handleBlur(
                                                                        'state'
                                                                    )}
                                                                    onChange={handleChange(
                                                                        'state'
                                                                    )}
                                                                    invalid={
                                                                        returnTouched(
                                                                            touched,
                                                                            'state'
                                                                        ) &&
                                                                        !!returnError(
                                                                            errors,
                                                                            'state'
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                style={{ flex: 1 }}
                                                                className="js-form-message col-4 d-flex form-group"
                                                            >
                                                                <LabeledFormField
                                                                    fieldTitle="Zip"
                                                                    required
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="zip"
                                                                    id="zip"
                                                                    aria-label="zip"
                                                                    error={returnError(
                                                                        errors,
                                                                        'zip'
                                                                    )}
                                                                    value={values.zip}
                                                                    onBlur={handleBlur(
                                                                        'zip'
                                                                    )}
                                                                    onChange={handleChange(
                                                                        'zip'
                                                                    )}
                                                                    invalid={
                                                                        returnTouched(
                                                                            touched,
                                                                            'zip'
                                                                        ) &&
                                                                        !!returnError(
                                                                            errors,
                                                                            'zip'
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="js-form-message form-group">
                                                        <div className="d-flex flex-row justify-content-start">
                                                            <div
                                                                style={{ flex: 1 }}
                                                                className="js-form-message col-6 d-flex form-group"
                                                            >
                                                                <ContryPhoneCodeSelect
                                                                    fieldTitle="Mobile phone"
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="phone"
                                                                    id="phone"
                                                                    aria-label="phone"
                                                                    phoneError={returnError(
                                                                        errors,
                                                                        'phone'
                                                                    )}
                                                                    phoneTouched={returnTouched(
                                                                        touched,
                                                                        'phone'
                                                                    )}
                                                                    codeValue={
                                                                        values?.code
                                                                    }
                                                                    phoneValue={
                                                                        values?.phone
                                                                    }
                                                                    countryValue={
                                                                        values?.country
                                                                    }
                                                                    onBlurPhone={handleBlur(
                                                                        'phone'
                                                                    )}
                                                                    onChangePhone={e => {
                                                                        handleChange(
                                                                            'phone'
                                                                        )(e);
                                                                    }}
                                                                    onBlurCode={handleBlur(
                                                                        'code'
                                                                    )}
                                                                    onChangeCode={value =>
                                                                        setFieldValue(
                                                                            'code',
                                                                            value
                                                                        )
                                                                    }
                                                                    onChangeCountry={value =>
                                                                        setFieldValue(
                                                                            'country',
                                                                            value
                                                                        )
                                                                    }
                                                                    invalid={
                                                                        returnTouched(
                                                                            touched,
                                                                            'phone'
                                                                        ) &&
                                                                        !!returnError(
                                                                            errors,
                                                                            'phone'
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                style={{ flex: 1 }}
                                                                className="js-form-message col-6 d-flex form-group"
                                                            >
                                                                <LabeledFormField
                                                                    fieldTitle="email"
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="email"
                                                                    id="email"
                                                                    // placeholder="Email"
                                                                    aria-label="email"
                                                                    error={returnError(
                                                                        errors,
                                                                        'email'
                                                                    )}
                                                                    value={values.email}
                                                                    onBlur={handleBlur(
                                                                        'email'
                                                                    )}
                                                                    onChange={handleChange(
                                                                        'email'
                                                                    )}
                                                                    invalid={
                                                                        returnTouched(
                                                                            touched,
                                                                            'email'
                                                                        ) &&
                                                                        !!returnError(
                                                                            errors,
                                                                            'email'
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="js-form-message form-group">
                                                        <div className="d-flex flex-row justify-content-start">
                                                            <div
                                                                style={{ flex: 1 }}
                                                                className="js-form-message col-6 d-flex form-group"
                                                            >
                                                                <LabeledFormField
                                                                    fieldTitle="issue type (a/c, refrigerator, toilet, etc...)"
                                                                    required
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="issue_type"
                                                                    id="issue_type"
                                                                    // placeholder="Email"
                                                                    aria-label="issue_type"
                                                                    error={returnError(
                                                                        errors,
                                                                        'issue_type'
                                                                    )}
                                                                    value={
                                                                        values.issue_type
                                                                    }
                                                                    onBlur={handleBlur(
                                                                        'issue_type'
                                                                    )}
                                                                    onChange={handleChange(
                                                                        'issue_type'
                                                                    )}
                                                                    invalid={
                                                                        returnTouched(
                                                                            touched,
                                                                            'issue_type'
                                                                        ) &&
                                                                        !!returnError(
                                                                            errors,
                                                                            'issue_type'
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                style={{ flex: 1 }}
                                                                className="js-form-message col-6 d-flex form-group"
                                                            >
                                                                <LabeledFormField
                                                                    fieldTitle="availability (when are you free for us to fix)"
                                                                    required
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="availability"
                                                                    id="availability"
                                                                    // placeholder="Email"
                                                                    aria-label="availability"
                                                                    error={returnError(
                                                                        errors,
                                                                        'availability'
                                                                    )}
                                                                    value={
                                                                        values.availability
                                                                    }
                                                                    onBlur={handleBlur(
                                                                        'availability'
                                                                    )}
                                                                    onChange={handleChange(
                                                                        'availability'
                                                                    )}
                                                                    invalid={
                                                                        returnTouched(
                                                                            touched,
                                                                            'availability'
                                                                        ) &&
                                                                        !!returnError(
                                                                            errors,
                                                                            'availability'
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="js-form-message form-group">
                                                        <div className="d-flex flex-row justify-content-start">
                                                            <div
                                                                style={{ flex: 1 }}
                                                                className="js-form-message col-12 d-flex form-group"
                                                            >
                                                                <LabeledFormField
                                                                    fieldTitle="description of issue"
                                                                    required
                                                                    rows="2"
                                                                    type="textarea"
                                                                    className="form-control"
                                                                    name="description"
                                                                    id="description"
                                                                    aria-label="description"
                                                                    error={returnError(
                                                                        errors,
                                                                        'description'
                                                                    )}
                                                                    value={
                                                                        values.description
                                                                    }
                                                                    onBlur={handleBlur(
                                                                        'description'
                                                                    )}
                                                                    onChange={handleChange(
                                                                        'description'
                                                                    )}
                                                                    invalid={
                                                                        returnTouched(
                                                                            touched,
                                                                            'description'
                                                                        ) &&
                                                                        !!returnError(
                                                                            errors,
                                                                            'description'
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex row justify-content-center align-items-center my-5">
                                                        <ConfirmFooter
                                                            cancel={() =>
                                                                history.push(
                                                                    routes.LANDING
                                                                )
                                                            }
                                                            submitFunc={handleSubmit}
                                                            submitText="Submit request"
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <div>Success UI!!!!</div>
                                            )}
                                        </Form>

                                        {/* <div className="col w-md-70 mx-md-auto justify-content-center align-items-center">
                                            <div className="mb-4">
                                                <h2 className="h5 font-weight-normal mb-0">
                                                    <span className="d-flex justify-content-start h4 font-weight-semi-bold">
                                                        {`Found Existing Sage ${
                                                            found && found?.length > 1
                                                                ? 'Members'
                                                                : 'Member'
                                                        }`}
                                                    </span>
                                                </h2>
                                            </div>

                                            <div className="col m-auto justify-content-start mb-4">
                                                {found.map(user => (
                                                    <div key={user.uid}>
                                                        <span className="d-block h5 mb-0">{`name: ${user?.first_name} ${user?.last_name}`}</span>
                                                        <span className="d-block h5 mb-0">{`email: ${user?.email}`}</span>
                                                        <span className="d-block h5 mb-0">{`phone: ${user?.phone?.number}`}</span>
                                                        {type === 'vendor' && (
                                                            <span className="d-block h5 mb-0">{`company name: ${values?.company_name}`}</span>
                                                        )}
                                                    </div>
                                                ))}
                                                <div className="row align-items-center, justify-content-start">
                                                    <Button
                                                        className="transition-3d-hover cancel-button m-3 font-weight-semi-bold btn-wide"
                                                        onClick={() =>
                                                            resettingNewMember('found')
                                                        }
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="transition-3d-hover m-3 font-weight-semi-bold btn-wide"
                                                        onClick={() =>
                                                            addingNewMember({
                                                                member,
                                                                exist: found
                                                            })
                                                        }
                                                    >
                                                        {`Add as new ${
                                                            type === 'resi'
                                                                ? 'Resident'
                                                                : 'Vendor'
                                                        }`}
                                                    </Button>
                                                </div>
                                            </div> */}
                                        {/* </div> */}
                                    </>
                                )}
                            </Formik>
                        </Col>
                    </Container>
                </div>
                {/* <div className="login">
                <Link className="btn btn-primary" to={routes.LOGIN}>
                    Login
                </Link>*/}
            </div>
        </div>
    );
};

const mapStateToProps = ({ maintenance }) => {
    const { error, loading } = maintenance;
    return { error, loading };
};

export default withRouter(
    connect(mapStateToProps, { addMaintenanceRequest })(MaintenanceRequest)
);
