// React
import React from 'react';

// Components

// Packages

// Router

const Dashboard = () => {
    return (
        <div className="d-flex main flex-column position-relative flex-auto minus-header">
            dashboard
        </div>
    );
};

export default Dashboard;
