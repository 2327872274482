// React
import React from 'react';

// Packages
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

// Pages
import { Landing, Login, Page404, MaintenanceRequest, Policies, Contact } from '../pages';

//Router
import * as routes from '../router/config/routes';
import AuthenticatedRouter from '../router/AuthenticatedRouter';
import { Header } from '../components';

// PrivateRoute Components makes sure a user who is not signed in/authenticated cannot visit the authenticated area like Dashboard Page...if they try and access Dashboard route they will be redirected to the Login Page
const PrivateRoute = ({ component: Component, authUser, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            authUser ? <Component {...props} /> : <Redirect to={routes.LOGIN} />
        }
    />
);

// PrevAuthedRoute Components makes sure a user who is signed in/authenticated cannot login again or sign up again...if they try and access the login route or sign up route they will be redirected to the Dashboard Page
const PrevAuthedRoute = ({ component: Component, authUser, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            !authUser ? <Component {...props} /> : <Redirect to={routes.AUTHENTICATED} />
        }
    />
);

const AppRouter = props => {
    const { match, history, authUser, userType, userData, userRole } = props;
    return (
        <Router>
            <Header props={props} />
            <Switch>
                <Route
                    exact
                    path={routes.LANDING}
                    match={match}
                    history={history}
                    component={() => <Landing authUser={authUser} />}
                />
                <Route
                    exact
                    authUser={authUser}
                    path={routes.MAINTENANCE_REQUEST}
                    component={props => (
                        <MaintenanceRequest authUser={authUser} {...props} />
                    )}
                />
                <Route
                    exact
                    authUser={authUser}
                    path={routes.POLICIES}
                    component={props => <Policies authUser={authUser} {...props} />}
                />
                <Route
                    exact
                    authUser={authUser}
                    path={routes.CONTACT}
                    component={props => <Contact authUser={authUser} {...props} />}
                />
                <PrevAuthedRoute
                    exact
                    authUser={authUser}
                    path={routes.LOGIN}
                    component={props => <Login authUser={authUser} {...props} />}
                />
                <PrivateRoute
                    authUser={authUser}
                    userData={userData}
                    path={routes.AUTHENTICATED}
                    component={props => (
                        <AuthenticatedRouter
                            userData={userData}
                            authUser={authUser}
                            userType={userType}
                            userRole={userRole}
                            {...props}
                        />
                    )}
                />
                <Route component={Page404} />
            </Switch>
        </Router>
    );
};

export default AppRouter;
