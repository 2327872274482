// React
import React from 'react';

// Assets
import ThurmontLogo from '../../assets/thurmont.png';

// Packages
import { Link, useLocation } from 'react-router-dom';

// Router
import * as routes from '../../router/config/routes';

const Header = props => {
    const location = useLocation();
    console.log(location);
    return (
        <div className="customHeader">
            <div className="row" style={{ margin: 0 }}>
                <Link
                    to={routes.LANDING}
                    className="d-flex align-items-center cursor-pointer"
                >
                    <img
                        src={ThurmontLogo}
                        alt="logo"
                        style={{ width: '13vw', marginRight: '1vw', marginLeft: '1vw' }}
                    />
                </Link>
                <div className="headerButtonContainer">
                    <Link
                        to={routes.LANDING}
                        style={{
                            color:
                                location?.pathname === routes.LANDING
                                    ? '#009245'
                                    : '#252c33'
                        }}
                    >
                        Home
                    </Link>
                    <Link
                        to={routes.MAINTENANCE_REQUEST}
                        style={{
                            color:
                                location?.pathname === routes.MAINTENANCE_REQUEST
                                    ? '#009245'
                                    : '#252c33'
                        }}
                    >
                        Maintenance Request
                    </Link>
                    <Link
                        to={routes.POLICIES}
                        style={{
                            color:
                                location?.pathname === routes.POLICIES
                                    ? '#009245'
                                    : '#252c33'
                        }}
                    >
                        Policies
                    </Link>
                    <Link
                        to={routes.CONTACT}
                        style={{
                            color:
                                location?.pathname === routes.CONTACT
                                    ? '#009245'
                                    : '#252c33'
                        }}
                    >
                        Contact
                    </Link>
                </div>
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        height: '120px',
                        marginLeft: '5vw',
                        width: '5vw'
                    }}
                >
                    <Link to={routes.LOGIN} style={{ color: '#006aa5' }}>
                        Admin
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Header;
