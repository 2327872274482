import React from 'react';
import { Link } from 'react-router-dom';
import { Header } from '../components';
import LandingBanner from '../assets/landing-banner.png';

const Landing = () => {
    return (
        <div className="" style={{ height: '2000px', backgroundColor: 'orange' }}>
            <div className="center">
                <div>
                    <img
                        src={LandingBanner}
                        alt="banner"
                        style={{
                            width: '100vw',
                            // height: '40vh',
                            objectFit: 'scale-down'
                        }}
                    />
                </div>
                {/* <div className="login">
                <Link className="btn btn-primary" to={routes.LOGIN}>
                    Login
                </Link>*/}
            </div>
        </div>
    );
};

export default Landing;
