// React
import React, { useEffect } from 'react';

// Components
import AppRouter from './router/Router';
// import { Loader } from './components';

// Hooks
import { useMountEffect } from './hooks/useMountEffect';

// Packages
import { connect } from 'react-redux';
import 'dotenv/config.js';
import * as Sentry from '@sentry/react';

// Redux - Actions, Reducers, Sagas
import { checkUserAuth } from './store/actions/Auth';

const App = ({
    history,
    match,
    authUser,
    checkUserAuth,
    appInitialized,
    userData,
    addingMembersFromList
}) => {
    useMountEffect(checkUserAuth);
    // useMountEffect(addingMembersFromList);

    // const getUserType = useCallback(() => {
    //     const org = userData.orgs.filter(org => org.id === userData.active_org_id);
    //     const type = org[0].sub_type;
    //     return type;
    // }, [userData]);

    // useEffect(() => {
    //     if (!authUser) {
    //         Sentry.configureScope(scope => scope.setUser(null));
    //     } else {

    //     }
    // }, [authUser, userData]);

    // if (!appInitialized)
    //     return (
    //         <div id="sage-splash-screen">
    //             <div className="center">
    //                 <div className="logo">
    //                     <img
    //                         src="../assets/logo-dark.png"
    //                         alt="logo"
    //                         style={{ maxWidth: '380px' }}
    //                     />
    //                     <div className="spinner-border text-primary mt-10" role="status">
    //                         <span className="sr-only">Loading...</span>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    return <AppRouter history={history} match={match} authUser={authUser} />;
};

const mapStateToProps = ({ auth, init }) => {
    const { authUser } = auth;
    const { appInitialized } = init;

    return { authUser, appInitialized };
};

export default connect(mapStateToProps, { checkUserAuth })(App);
