// React
import React from 'react';

// Components
import { Container, Col } from 'reactstrap';
// import WarningIcon from '../assets/svg/Warning.svg';

const Page404 = () => {
    return (
        <div className="minus-header bg-dark">
            <Container className="space-4">
                <Col className="d-flex flex-column justify-items-center align-items-center w-md-75 mx-md-auto border form-round bg-white p-2">
                    {/* <img
                    className="row js-svg-injector max-width-35 mb-3"
                    src={WarningIcon}
                    alt="Warning"
                /> */}
                    <h2
                        className="display-5 mt-5 mb-4"
                        style={{
                            fontWeight: '600'
                        }}
                    >
                        Something went wrong.
                    </h2>
                    <p style={{ fontSize: '1rem' }} className="w-md-75 text-center mb-3">
                        The page you are looking for could not be found. If you are having
                        trouble please contact your administrator.
                    </p>
                    {/* <small className="mb-5 w-md-75 text-center">
                        Note: if you are trying to register in your community, please find
                        the short-code link that was sent and try again.
                    </small> */}
                </Col>
            </Container>
        </div>
    );
};

export default Page404;
