import { combineReducers } from 'redux';
import AuthReducer from './Auth';
import InitialReducer from './Initial';
import MaintenanceReducer from './Maintenance';
import { LOGOUT_USER } from '../actions/types';

const appReducer = combineReducers({
    auth: AuthReducer,
    init: InitialReducer,
    maintenance: MaintenanceReducer
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_USER) {
        const { init } = state;
        state = { init };
    }
    return appReducer(state, action);
};

export default rootReducer;
