import {
    ADD_MAINTENANCE_REQUEST,
    ADD_MAINTENANCE_REQUEST_SUCCESS,
    ADD_MAINTENANCE_REQUEST_FAILURE
} from './types';

export const addMaintenanceRequest = request => {
    return {
        type: ADD_MAINTENANCE_REQUEST,
        payload: request
    };
};

export const addMaintenanceRequestSuccess = () => {
    return {
        type: ADD_MAINTENANCE_REQUEST_SUCCESS
    };
};

export const addMaintenanceRequestFailure = error => {
    return {
        type: ADD_MAINTENANCE_REQUEST_FAILURE,
        payload: error
    };
};
